'use client';

import { Flex, FlexProps, Heading, IconButton } from '@chakra-ui/react';
import { IconCaretLeft, layoutMinWidth, uiColors } from '@cryptofi/core-ui';
import { useRouter } from 'next/navigation';

import GlobalModals from '~/components/GlobalModals/GlobalModals';
import { AllAssetIds, AssetType } from '~/types';

import Footer from './Footer';
import MobileNav from './MobileNav';
import PrimaryNav from './PrimaryNav';

interface Props extends FlexProps {
  showNav?: boolean;
  navHeading?: {
    text: string;
    icon?: React.ReactElement;
  };
  navSubHeading?: string;
  isSubPage?: boolean;
  noPadding?: boolean;
  bgColor?: string;
  hideTopNavBar?: boolean;
  defaultAssetId?: AllAssetIds;
  defaultAssetType?: AssetType;
  maxContentWidth?: string;
}

const pageContentMaxWidth = '87.5rem';
const subPageContentMaxWidth = '75rem';

const PageLayout = ({
  children,
  showNav = true,
  navHeading,
  navSubHeading,
  isSubPage,
  noPadding,
  bgColor,
  hideTopNavBar,
  defaultAssetId,
  defaultAssetType,
  maxContentWidth,
  ...rest
}: Props) => {
  const router = useRouter();

  return (
    <>
      <Flex flexDir={{ base: 'column', lg: 'row' }} minH="100vh" minWidth={layoutMinWidth} {...rest}>
        {showNav && !isSubPage && (
          <PrimaryNav
            display={{
              base: 'none',
              lg: 'flex',
            }}
          />
        )}

        {showNav && (
          <MobileNav
            navHeading={navHeading}
            subHeading={navSubHeading}
            isSubPage={isSubPage}
            display={{
              base: 'flex',
              lg: 'none',
            }}
          />
        )}

        <Flex
          alignItems="center"
          justifyContent="center"
          flexDir="column"
          width="full"
          marginX="auto"
          backgroundColor={bgColor || uiColors.white()}
          flexGrow="1"
        >
          {!hideTopNavBar && (
            <Flex
              position="sticky"
              top="0"
              zIndex="4" // higher than CfAreaChart, lower than PrimaryNav tooltips.
              w="full"
              justifyContent={isSubPage ? 'space-between' : 'center'}
              alignItems="center"
              px="3"
              py="4"
              display={{ base: 'none', lg: 'flex' }}
              backgroundColor={uiColors.white()}
              borderBottom="1px"
              borderBottomColor={uiColors.coolElegance()}
              height="16"
            >
              <IconButton
                position="absolute" // so heading is centered
                display={isSubPage ? 'flex' : 'none'}
                aria-label="Back to previous page"
                icon={<IconCaretLeft />}
                variant="ghost"
                size="sm"
                onClick={() => router.back()}
              />

              <Flex flex={1} justifyContent="center" alignItems="center" gap={2}>
                {navHeading?.icon && <Flex alignItems="center">{navHeading.icon}</Flex>}

                <Heading as="h1" size="md" textAlign="center">
                  {navHeading?.text}
                </Heading>

                {navSubHeading && (
                  <Heading as="h2" fontSize="md" noOfLines={1} color={uiColors.sonicSilver()} fontFamily="body">
                    {navSubHeading}
                  </Heading>
                )}
              </Flex>
            </Flex>
          )}

          <Flex
            w="full"
            maxWidth={maxContentWidth || (isSubPage ? subPageContentMaxWidth : pageContentMaxWidth)}
            padding={noPadding ? '0' : '4'}
            direction="column"
            flexGrow="1"
            pb="10"
            minHeight={{
              // push footer to bottom
              base: '87vh',
              md: '88.5vh',
            }}
          >
            {children}
          </Flex>

          <Footer />
        </Flex>
      </Flex>

      <GlobalModals defaultAssetId={defaultAssetId} defaultAssetType={defaultAssetType} />
    </>
  );
};

export default PageLayout;

'use client';

import {
  Box,
  Button,
  CheckboxGroup,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  ModalProps,
  Text,
  useCheckboxGroup,
} from '@chakra-ui/react';
import { CfCheckbox, CfForm, CfModal, CfSelect, CfSpinner, uiColors } from '@cryptofi/core-ui';
import { yupResolver } from '@hookform/resolvers/yup';
import { AxiosError } from 'axios';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';

import { usePostRiskToleranceQuestionnaire } from '~/hooks';
import { riskToleranceAnswerLookup } from '~/types';
import { getErrorDetail } from '~/utils';

const RiskToleranceQuestionnaireModal = ({
  liquidityProviderAccountId,
  onClose,
  isOpen,
  ...rest
}: { liquidityProviderAccountId: string } & Omit<ModalProps, 'children'>) => {
  const postRiskToleranceQuestionnaire = usePostRiskToleranceQuestionnaire({
    liquidityProviderAccountId,
  });

  const riskToleranceQuestionnaireSchema = Yup.object().shape({
    investmentObjective: Yup.array()
      .required('please select at least one investment type')
      .min(1, 'please select at least one investment objective'),
    timeHorizon: Yup.string().required('please select a time horizon option'),
    riskWillingness: Yup.string().required('please select a risk level'),
    riskReaction: Yup.string().required('please select an option'),
    investmentExperience: Yup.string().required('please select an option'),
    currentInvestmentTypes: Yup.array()
      .required('please select at least one investment type')
      .min(1, 'please select at least one investment type'),
  });

  const { handleSubmit, register, setValue, getValues, formState } = useForm({
    resolver: yupResolver(riskToleranceQuestionnaireSchema),
    mode: 'onSubmit',
    reValidateMode: 'onChange',
  });

  const isLoading = formState.isSubmitting || formState.isLoading || postRiskToleranceQuestionnaire.isPending;

  const investmentObjectiveOptions = [
    { label: 'Capital Preservation', value: riskToleranceAnswerLookup.CapitalPreservation },
    { label: 'Income Generation', value: riskToleranceAnswerLookup.IncomeGeneration },
    { label: 'Growth of capital', value: riskToleranceAnswerLookup.GrowthOfCapital },
    { label: 'Speculation', value: riskToleranceAnswerLookup.Speculation },
  ];

  const currentInvestmentTypesOptions = [
    { label: 'Cash or Cash Equivalents', value: riskToleranceAnswerLookup.CashAndCashEquivalents },
    { label: 'Bonds', value: riskToleranceAnswerLookup.Bonds },
    { label: 'Stocks', value: riskToleranceAnswerLookup.Stocks },
    { label: 'Mutual Funds/ETFs', value: riskToleranceAnswerLookup.MutualFunds },
    { label: 'Real Estate', value: riskToleranceAnswerLookup.RealEstate },
    {
      label: 'Alternatives (e.g., private equity, hedge funds)',
      value: riskToleranceAnswerLookup.AlternativeInvestments,
    },
  ];

  const handleInvestmentObjectiveChange = (value: Array<string>): void => {
    setValue('investmentObjective', value, { shouldDirty: true, shouldTouch: true, shouldValidate: true });
  };

  const handleCurrentInvestmentTypesChange = (value: Array<string>): void => {
    setValue('currentInvestmentTypes', value, { shouldDirty: true, shouldTouch: true, shouldValidate: true });
  };

  const { value: investmentObjectiveValue, getCheckboxProps: getInvestmentObjectiveCheckboxProps } = useCheckboxGroup({
    defaultValue: [],
  });

  const { value: currentInvestmentTypesValue, getCheckboxProps: getCurrentInvestmentTypesCheckboxProps } =
    useCheckboxGroup({
      defaultValue: [],
    });

  const onSubmit = async () => {
    const formValues = getValues();
    const answers = {
      answers: [
        {
          questionId: 1,
          answers: formValues.investmentObjective,
        },
        {
          questionId: 2,
          answers: [formValues.timeHorizon],
        },
        {
          questionId: 3,
          answers: [formValues.riskWillingness],
        },
        {
          questionId: 4,
          answers: [formValues.riskReaction],
        },
        {
          questionId: 5,
          answers: [formValues.investmentExperience],
        },
        {
          questionId: 6,
          answers: formValues.currentInvestmentTypes,
        },
      ],
    };
    postRiskToleranceQuestionnaire.mutate(answers, {
      onSuccess: () => {
        onClose();
        sessionStorage.setItem('showRiskToleranceQuestionnaire', 'false');
      },
    });
  };

  return (
    <CfModal
      // returnFocusOnClose prevents scrolling back to the triggering element on close
      // TODO should be removed if we ever unwind blockScrollOnMount used in core UI
      returnFocusOnClose={false}
      size="2xl"
      closeOnEsc={!formState.isSubmitting}
      closeOnOverlayClick={!formState.isSubmitting}
      onClose={onClose}
      onCloseComplete={() => {}}
      isDisabledCloseButton={formState.isSubmitting}
      isOpen={isOpen}
      headerContent={
        <Heading size="md" textAlign="center">
          Account Recommendations
        </Heading>
      }
      {...rest}
    >
      <Flex flexDir="column" w="full" px={{ base: 0, sm: 10, md: 20 }} py="5">
        <Box
          // uses conditional display to preserve field values in case of server error after submitting
          display={!isLoading ? 'block' : 'none'}
        >
          <Heading as="h2" size="sm" color={uiColors.sonicSilver()}>
            Risk tolerance questionnaire
            <Text as="span" color={uiColors.heroicRed()}>
              *
            </Text>
          </Heading>

          <Text color={uiColors.sonicSilver()} fontSize="sm" mb="4">
            In order to recommend a portfolio, we need to ask you a few questions.
          </Text>

          {postRiskToleranceQuestionnaire.error && (
            <Text color={uiColors.heroicRed()} textAlign="center">
              {getErrorDetail(postRiskToleranceQuestionnaire.error as AxiosError)}
            </Text>
          )}

          <CfForm id="kycForm" onSubmit={handleSubmit(onSubmit)} mt="6">
            <Flex flexDirection="column" gap="6">
              <FormControl isInvalid={Boolean(formState.errors.investmentObjective)}>
                <FormLabel>{'What are your primary investment objectives? (Select all that apply)'}</FormLabel>

                <CheckboxGroup value={investmentObjectiveValue} onChange={handleInvestmentObjectiveChange}>
                  {investmentObjectiveOptions.map((option) => (
                    <Flex key={option.value} my="4" alignItems="center">
                      <CfCheckbox
                        key={option.value}
                        size="md"
                        name="investmentObjective"
                        width="auto"
                        label={option.label}
                        labelPosition="right"
                        isInvalid={Boolean(formState.errors.investmentObjective)}
                        {...getInvestmentObjectiveCheckboxProps({ value: option.value })}
                      />
                    </Flex>
                  ))}
                </CheckboxGroup>

                {formState.errors.investmentObjective && (
                  <FormErrorMessage>{formState.errors.investmentObjective.message}</FormErrorMessage>
                )}
              </FormControl>

              <CfSelect
                name="timeHorizon"
                label="What is your investment time horizon? When is the earliest you would need to withdraw?"
                register={register}
                errorMessage={formState.errors.timeHorizon?.message}
                defaultValue=""
              >
                <option disabled value="">
                  Select one
                </option>

                <option value={riskToleranceAnswerLookup.LessThan1Year} data-testid={`timeHorizon-option-4`}>
                  {'Less than 1 year'}
                </option>

                <option value={riskToleranceAnswerLookup.Value13Years} data-testid={`timeHorizon-option-3`}>
                  {'1-3 years'}
                </option>

                <option value={riskToleranceAnswerLookup.Value35Years} data-testid={`timeHorizon-option-2`}>
                  {'3-5 years'}
                </option>

                <option value={riskToleranceAnswerLookup.MoreThan5Years} data-testid={`timeHorizon-option-1`}>
                  {'More than 5 years'}
                </option>
              </CfSelect>

              <CfSelect
                name="riskWillingness"
                label="How would you describe your willingness to take risks in your investments?"
                register={register}
                errorMessage={formState.errors.riskWillingness?.message}
                defaultValue=""
              >
                <option disabled value="">
                  Select one
                </option>

                <option value={riskToleranceAnswerLookup.VeryConservative} data-testid={`riskWillingness-option-1`}>
                  {'Very Conservative (avoid risk)'}
                </option>

                <option value={riskToleranceAnswerLookup.Conservative} data-testid={`riskWillingness-option-2`}>
                  {'Conservative (willing to take minimal risk)'}
                </option>

                <option value={riskToleranceAnswerLookup.Moderate} data-testid={`riskWillingness-option-3`}>
                  {'Moderate (willing to take moderate risk)'}
                </option>

                <option value={riskToleranceAnswerLookup.Aggressive} data-testid={`riskWillingness-option-4`}>
                  {'Aggressive (willing to take significant risk)'}
                </option>
              </CfSelect>

              <CfSelect
                name="investmentExperience"
                label="How would you rate your understanding of investments?"
                register={register}
                errorMessage={formState.errors.investmentExperience?.message}
                defaultValue=""
              >
                <option disabled value="">
                  Select one
                </option>

                <option value={riskToleranceAnswerLookup.NoKnowledge} data-testid={`investmentExperience-option-1`}>
                  {'None'}
                </option>

                <option value={riskToleranceAnswerLookup.Basic} data-testid={`investmentExperience-option-2`}>
                  {'Basic'}
                </option>

                <option value={riskToleranceAnswerLookup.Moderate} data-testid={`investmentExperience-option-3`}>
                  {'Moderate'}
                </option>

                <option value={riskToleranceAnswerLookup.Advanced} data-testid={`investmentExperience-option-4`}>
                  {'Advanced'}
                </option>
              </CfSelect>

              <CfSelect
                name="riskReaction"
                label="How would you react if your investment portfolio decreased by 20% in value?"
                register={register}
                errorMessage={formState.errors.riskReaction?.message}
                defaultValue=""
              >
                <option disabled value="">
                  Select one
                </option>

                <option value={riskToleranceAnswerLookup.PanicAndSell} data-testid={`riskReaction-option-1`}>
                  {'Panic and sell all investments'}
                </option>

                <option value={riskToleranceAnswerLookup.SellToLimitLosses} data-testid={`riskReaction-option-2`}>
                  {'Sell some investments to limit losses'}
                </option>

                <option value={riskToleranceAnswerLookup.HoldAndWait} data-testid={`riskReaction-option-3`}>
                  {'Hold my investments and wait for recovery'}
                </option>

                <option value={riskToleranceAnswerLookup.BuyMore} data-testid={`riskReaction-option-4`}>
                  {'Buy more investments to take advantage of lower prices'}
                </option>
              </CfSelect>

              <FormControl isInvalid={Boolean(formState.errors.currentInvestmentTypes)}>
                <FormLabel>{'What types of investments do you currently hold? (Select all that apply)'}</FormLabel>

                <CheckboxGroup value={currentInvestmentTypesValue} onChange={handleCurrentInvestmentTypesChange}>
                  {currentInvestmentTypesOptions.map((option) => (
                    <Flex key={option.value} my="4" alignItems="center">
                      <CfCheckbox
                        key={option.value}
                        size="md"
                        name="currentInvestmentTypes"
                        width="auto"
                        label={option.label}
                        labelPosition="right"
                        isInvalid={Boolean(formState.errors.currentInvestmentTypes)}
                        {...getCurrentInvestmentTypesCheckboxProps({ value: option.value })}
                      />
                    </Flex>
                  ))}
                </CheckboxGroup>

                {formState.errors.currentInvestmentTypes && (
                  <FormErrorMessage>{formState.errors.currentInvestmentTypes.message}</FormErrorMessage>
                )}
              </FormControl>
            </Flex>

            <Button type="submit" size="lg" isLoading={postRiskToleranceQuestionnaire.isPending} mt={6}>
              Submit
            </Button>
          </CfForm>
        </Box>

        {isLoading && (
          <Flex flexDir="column" gap="4" w="full" alignItems="center">
            <CfSpinner />
          </Flex>
        )}
      </Flex>
    </CfModal>
  );
};

export default RiskToleranceQuestionnaireModal;

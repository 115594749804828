'use client';

import { Center, Text } from '@chakra-ui/react';
import { CfSpinner, layoutMinWidth } from '@cryptofi/core-ui';
import dayjs from 'dayjs';
import { jwtDecode, JwtPayload } from 'jwt-decode';
import { useSearchParams } from 'next/navigation';
import { useEffect, useState } from 'react';
import Cookies from 'universal-cookie';

import { useAuthenticateUser, useAxiosContext, useGetAuth, useGlobalStore, usePostAuth } from '~/hooks';
import isDev from '~/utils/isDev';

interface CustomJwtPayload extends JwtPayload {
  'custom:cws-fi-id': string;
  'custom:cws-fi-user-id': string;
}

const Authentication = ({ children }: { children: React.ReactElement }) => {
  const [authCode, setAuthCode] = useState<string | null>(null);
  const { setHeaders } = useAxiosContext();
  const { isVercelDev, isLocalDev } = isDev();

  const [userAuthInfo, setUserAuthInfo] = useGlobalStore((state) => [state.userAuthInfo, state.setUserAuthInfo]);

  const getAuth = useGetAuth({
    authCode,
  });
  const postAuth = usePostAuth();
  const authenticateUser = useAuthenticateUser();
  const searchParams = useSearchParams();

  // User got redirected to us from NCR, save auth code from URL in state
  useEffect(() => {
    if (searchParams.has('code')) {
      setAuthCode(searchParams.get('code'));
    }
  }, [searchParams]);

  // check token expiration
  useEffect(() => {
    if (userAuthInfo?.expiryDate) {
      // When the user is authenticated, we check if the token is expired
      if (dayjs().unix() >= userAuthInfo?.expiryDate) {
        // enable getAuth fetching again
        // authorizeUser will be called again per dependency
        setAuthCode(null);
      }
    }
  }, [userAuthInfo?.expiryDate]);

  // handle typical auth flow, with additional logic for bypassing on dev
  useEffect(() => {
    const cookies = new Cookies();

    // enable bypassing auth for local and vercel dev, by setting a valid JWT in local storage (or cookie for AccessFlow)
    const localIdToken = localStorage.getItem('investifi:idToken') || cookies.get('investifi:idToken');
    const canBypassAuth = localIdToken && !userAuthInfo?.idToken && (isVercelDev || isLocalDev);

    if (canBypassAuth) {
      setUserAuthInfo({
        idToken: localIdToken,
        expiresIn: '86400',
        expiryDate: dayjs().add(86400, 'second').unix(),
      });

      const decoded = jwtDecode<CustomJwtPayload>(localIdToken);

      setHeaders!({
        Authorization: `Bearer ${localIdToken}`,
        'fi-id': decoded['custom:cws-fi-id'],
        'user-account-id': decoded['custom:cws-fi-user-id'],
      });
    } else if (!userAuthInfo?.idToken) {
      authenticateUser({ authCode, redirectUrl: getAuth?.data?.redirectUrl ?? '', postAuth });
    }
  }, [
    authCode,
    postAuth,
    getAuth?.data,
    authenticateUser,
    userAuthInfo?.idToken,
    setHeaders,
    setUserAuthInfo,
    isLocalDev,
    isVercelDev,
  ]);

  // render the app for an authenticated user
  if (userAuthInfo?.idToken) {
    return children;
  }

  // TODO log any errors

  return (
    <Center minHeight="100vh" flexDirection="column" gap="4" textAlign="center" minW={layoutMinWidth} p="6">
      {(getAuth.isLoading || postAuth.isPending) && (
        <>
          <Text>Welcome to your personal investment platform. Loading your portfolio...</Text>

          <CfSpinner margin={5} boxSize="12" />
        </>
      )}

      {(getAuth.isError || postAuth.isError) && (
        <Text>Something went wrong while authenticating, please try again.</Text>
      )}
    </Center>
  );
};

export default Authentication;

import { useMutation, useQueryClient } from '@tanstack/react-query';
import { Camelized, decamelizeKeys } from 'humps';

import { RiskToleranceQuestionnaireResponseInput } from '~/codegen/types';
import { qkUser, useAxiosContext } from '~/hooks';
import { apiRoutes } from '~/routes';

const usePostRiskToleranceQuestionnaire = ({ liquidityProviderAccountId }: { liquidityProviderAccountId: string }) => {
  const queryClient = useQueryClient();
  const { apiClient } = useAxiosContext();
  return useMutation({
    mutationFn: async (body: Camelized<RiskToleranceQuestionnaireResponseInput>) => {
      return apiClient?.post(apiRoutes.riskToleranceQuestionnaire(liquidityProviderAccountId), decamelizeKeys(body));
    },
    onSuccess: () => {
      sessionStorage.setItem('showRiskToleranceQuestionnaire', 'false'); // TODO: remove all instances of showRiskToleranceQuestionnaire
      queryClient.invalidateQueries({
        predicate: (query) => {
          return query.queryKey.includes(qkUser);
        },
      });
    },
  });
};

export default usePostRiskToleranceQuestionnaire;
